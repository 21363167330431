@import 'var';

header{
    position: relative;
    z-index: 100;
    margin-right: 260px;
}

.main-aside{
    position: fixed;
    right: 0;
    top: 0;
    bottom:0;
    width: 260px;
    margin-right: 0;
    border-left: 1px solid #dee2ed;
}

.header{
    height: 45px;
    padding-left: 30px;
    border-top: 1px solid $main;
    border-bottom: 1px solid $main;
    transition: $transition-half;
    position: relative;
    z-index: 100;
    background: #fff;
}

.header-unvis{
    .header{
        margin-top: -72px;
    }
    .header-toggler{
        top: 18px;
        transform: rotate(180deg);
    }
}

.topmenu{
    margin: 0 0 0 20px;
    height: 100%;
    float: left;
    font-weight: 100;
    position: relative;
    width: 400px;

    &__item{
        width: 100px;
        list-style-type: none;
        display: block;
        height: 100%;
        float: left;

        &__link{
            width: 100px;
            cursor: pointer;
            color: $main;
            height: 100%;
            line-height: 45px;
            list-style-type: none;
            display: block;
            position: relative;
            text-align: center;
        }

        &:nth-child(1){
            &.active,
            &:hover{
                ~ .topmenu__marker{
                    left: 0;
                }
            }
        }

        &:nth-child(2){
            &.active,
            &:hover{
                ~ .topmenu__marker{
                    left: 100px;
                }
            }
        }

        &:nth-child(3){
            &.active,
            &:hover{
                ~ .topmenu__marker{
                    left: 200px;
                }
            }
        }

        &:nth-child(4){
            &.active,
            &:hover{
                ~ .topmenu__marker{
                    left: 300px;
                }
            }
        }

        &:hover{
            .topmenu__item__submenu{
                top: 100%;
                display: block;
            }
        }

        &__submenu{
            display: none;
            margin: 0;
            position: absolute;
            background-color: #fff;
            z-index: 9999;
            min-width: 150px;
            top: 110%;
            box-shadow: 0 8px 16px 0 rgba(50, 113, 254, 0.3);
            // transform: translateX(0) translateY(20px);
            // transition: all 0.2s ease-in-out;
            transition: $transition;

            &__content{

                list-style-type: none;
                a{
                    text-align: left;
                    height: 45px;
                    font-size: 14px;
                    padding: 0 25px;
                    display: block;

                    &:hover{

                        color: #fff;
                        background-color: $main;

                        a{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &__marker{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 2px;
        background: $main;
        transition: $transition;
    }
}

.breadcrumbs{
    margin: 0;
    padding: 0 0 0 10px;
    list-style-type: none;
    float: left;
    height: 100%;


    li{
        display: flex;
        height: 100%;
        float: left;
        margin-right: 6px;

        *{
            align-self: center;
        }
    }

    &__devider{
        color: #a1a7b7;
        font-size: 16px;
    }

    &__page-name{
        font-size: 14px;
    }

    &__home{
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        position: relative;

        &:after{
            content: "";
            display: block;
            width: 36px;
            height: 36px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            z-index: 100;
            transition: $transition;
        }
    }
}

.user-menu{
    float: right;
    margin-right: 30px;
    display: flex;
    height: 100%;
    padding: 0 15px;
    position: relative;
    min-width: 220px;
    justify-content: flex-end;
    transition: $transition;

    &__name{
        color: $main;
        font-size: 14px;
        font-weight: 400;
        align-self: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 9px;

        .material-icons{
            font-size: 16px;
            transition: $transition;
            text-align: center;
            position: relative;
            top: 3px;
            right: -8px;
        }
    }

    &__list{
        position: absolute;
        left: 0;
        right: 0;
        top: 80%;
        margin: 0;
        padding: 0 15px;
        list-style-type: none;
        background: #fff;
        -webkit-box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);
        -moz-box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);
        box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -ms-transition: $transition;
        -o-transition: $transition;
        transition: $transition;
        max-height: 0;
        overflow: hidden;
        z-index: 250;
    }

    &__item{

    }

    &__action{
        display: flex;
        margin: 0 -15px;
        padding: 13px 15px;
        transition: $transition;
        justify-content: flex-end;

        &:hover{
            background: $main;
            color: #fff;
        }

        & *{
            align-self: center;
        }

        .material-icons{
            margin-left: 10px;
            font-size: 18px;
        }
    }

    &.act{
        background: #fff;
        -webkit-box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);
        -moz-box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);
        box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);

        .user-menu__list{
            top: 100%;
            opacity: 1;
            visibility: visible;
            max-height: none;
            overflow: visible;
        }

        .user-menu__name{

            .material-icons{
                transform: rotate(180deg);
            }
        }
    }
}

.subheader{
    min-height: 45px;
    padding: 0 30px;
    position: relative;
    background: $background;
    margin-right: 260px;

    &-tabs{
        float: left;
        margin: 0;
        padding: 0;
        list-style-type: none;
        min-height: 45px;
        max-height: 92px;

        &__item{
            float: left;
            height: 100%;
            
            a{
                color: $main;
            }
        }

        &__link{
            padding: 0 25px;
            font-size: 14px;
            font-weight: 100;
            color: #3A424F;
            display: flex;
            height: 45px;
            transition: $transition;

            &.act{
                color: #3A424F;
                -webkit-box-shadow: inset 0px -1px 0px 0px #3A424F;
                -moz-box-shadow: inset 0px -1px 0px 0px #3A424F;
                box-shadow: inset 0px -1px 0px 0px #3A424F;
            }

            & *{
                align-self: center;
            }
        }
        
        &__text{
            padding: 0 25px;
            font-size: 14px;
            font-weight: 100;
            display: flex;
            height: 45px;
            transition: $transition;
            color: #ccc;

            & *{
                align-self: center;
            }
        }
    }
}

.header-toggler{
    display: none;
    position: absolute;
    width: 36px;
    height: 36px;
    top: -53px;
    right: 36px;
    // display: flex;
    justify-content: center;
    cursor: pointer;
    transition: $transition;

    &:after{
        content: "";
        display: block;
        width: 36px;
        height: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        z-index: 100;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        border-radius: 18px;
        transition: $transition;
        border: 1px solid #80A8FF;
    }

    &:hover{

        &:after{
            width: 50px;
            height: 50px;
            border: 1px solid transparent;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px;
            transition: $transition;
        }
    }

    .material-icons{
        align-self: center;
        color: #3271FE;
    }
}

.add-order{
    min-width: 160px;
    height: 38px;
    line-height:100%;
    padding: 0 16px 0 10px;
    background-color: $main;
    border-radius: 2px;
    display: flex;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    transition: $transition;
    margin-left: 30px !important;
    margin-bottom: 1px;
    margin-right: 30px;
    cursor: pointer;

    &:focus{
        color: #fff;
    }

    &:hover{

        color: #fff;

        .material-icons{

            &:after{
                width: 30px;
                height: 30px;
                border: 1px solid rgba(255, 255, 255, .5);
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;
                transition: $transition;
            }
        }
    }

    .material-icons{
        font-size: 20px;
        flex-grow: 3;
        text-align: center;
        position: relative;
        margin-right: 10px;

        &:after{
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            z-index: 9;
            border: 0;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            transition: $transition;
        }
    }

    &__text{
        flex-grow: 1;
    }
}

aside .action-list{
    height: 26px;
}

.action-list{
    display: flex;
    height: 45px;
    justify-content: flex-end;

    .add-order,
    .icon-btn{
        align-self: center;
        margin-left: 10px;
    }
}